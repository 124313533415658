import React from "react"
import HomeHeroSubscription from "./HomeHeroSubscription"
import SocialLinks from "./SocialLinks"
import { graphql, useStaticQuery } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import ffGlitchMp4 from "../assets/images/ff-blue-glitch.mp4"
import bgDots from "../assets/images/bg-dots.png"
import circlePortrait from "../assets/images/hero-portrait-circle.png"

const HomeHero = () => {
  const { site } = useStaticQuery(graphql`
    query coverImage {
      site {
        siteMetadata {
          title
          cover
        }
      }
    }
  `)

  return (
    <section className="home-cover-area">
      <div className="container">
        <div
          className={`row home-cover-wrap${
            site.siteMetadata.cover && site.siteMetadata.cover !== ""
              ? " has-cover-image"
              : ""
          }`}
        >
          {site.siteMetadata.cover && site.siteMetadata.cover !== "" && (
            <div className="col-lg-4">
              <div className="cover-img-container">
                <div className="cover-img-wrap">
                {/* <video className="hero-ff-video" width="600" loop muted autoPlay >
                  <source src={ffGlitchMp4} type="video/mp4"/>
                </video> */}
                <img src={bgDots}
                    alt={`bg dots pattern`}
                    className="bg-dots-img"
                  />
                {/* <Video className="hero-ff-video" src={ffGlitchMp4} width="600" /> */}
                <img 
                  src={circlePortrait}
                  alt={`${site.siteMetadata.title} Cover`}
                  width={400}
                  height={400}
                  style={{ position: "absolute", borderRadius: "50%" }}
                />
                  {/* <StaticImage
                    src="../assets/images/hero-portrait-circle.png"
                    alt={`${site.siteMetadata.title} Cover`}
                    width={400}
                    height={400}
                    style={{ position: "absolute", borderRadius: "50%" }}
                    placeholder="blurred"
                  /> */}
                  {/* <img
                    loading="lazy"
                    srcSet=""
                    src={site.siteMetadata.cover}
                    alt={`${site.siteMetadata.title} Cover`}
                  ></img> */}

                  {/* <div className="dot-parent dot-1">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-2">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-3">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-4">
                    <div className="dot"></div>
                  </div>
                  <div className="dot-parent dot-5">
                    <div className="dot"></div>
                  </div> */}
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-8">
            <div className="home-cover-content-wrap">
              <div className="hero-heading first bomber-i">no bs. no fluff.</div>
              <div className="hero-heading bomber-i"><span className="highlighted">raw</span>self improvement</div>
              {/* <div className="intro-description">
                An architect, software developer, YouTuber and keynote speaker.
                On this site I write about my learning and experience.
              </div> */}
              <HomeHeroSubscription />
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHero
