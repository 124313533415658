import React, {useContext} from "react"
import { handleResetPw } from "../../services/auth"
import { useUrlQuery, clearUrlParams } from "../../services/helpers"
import ffWhiteGlitch from "../../assets/images/ff-white-glitch.gif"
import Modal from "./Modal"
import { ModalContext } from "../Wrapper";
import toast from "react-hot-toast"

const ResetPwModal = () => {
  const {setShowLoginModal} = useContext(ModalContext);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [resetPasswordId, setResetPasswordId] = React.useState("");


  // url params check
  const checkResetPasswordId = useUrlQuery("forgot");
  
  // user is resetting their password
  if(checkResetPasswordId && !resetPasswordId) {
    clearUrlParams();
    setResetPasswordId(checkResetPasswordId)
    setTimeout(() => {
      console.log("Reset Pw ID Detected", checkResetPasswordId)
      if(!modalIsOpen) {
        setModalIsOpen(true);
      }
    }, 500);
  }

  if(!modalIsOpen) {
    return null;
  }

  const closeThisModalAndOpenLoginModal = () => {
    setModalIsOpen(false);
    setShowLoginModal(true);
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const result = await handleResetPw({resetPasswordId, newPassword: password});
    console.log(result)
    if(result && result.message) {
      // successful
      toast.success(result.message)
      setPassword("")
      closeThisModalAndOpenLoginModal();
    }
    else {
      // failed
      const errorMsg = result.error ? result.error : "An error occurred"
      toast.error(errorMsg)
    }
  }

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
          <div className="row text-center">
            <div className="col-12 mb-20">
              <img className="modal-logo" src={ffWhiteGlitch} alt="Logo" />
            </div>
            <div className="col-12">
              <div className="form-wrap capped-width">
                <form onSubmit={e => handleSubmit(e)}>
                  <label htmlFor="resetPwPassword" className="sr-only">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="password form-field input-field"
                    id="resetPwPassword"
                    placeholder="New Password"
                    required
                    autoComplete="off"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <br/>
                  <button className="btn form-field" type="submit">
                    <span>Change Password</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
  )
}

export default ResetPwModal
