import React, {useContext} from "react"
import { handleActivation } from "../../services/auth"
import { useUrlQuery, clearUrlParams } from "../../services/helpers"
import loadingAnim from "../../assets/images/loading-anim.gif"
import checkAnim from "../../assets/images/check-anim.gif"
import crossAnim from "../../assets/images/cross-anim.gif"
import Modal from "./Modal"
import { ModalContext } from "../Wrapper";

const ActivationModal = () => {
  const {setShowLoginModal, setShowSignUpModal} = useContext(ModalContext);

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalWasOpen, setModalWasOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  // url params check
  const activationId = useUrlQuery("activate");
  //const forgotPwId = useUrlQuery("forgot");
  
  // user is activating their account
  if(activationId && !modalWasOpen) {
    setTimeout(() => {
      console.log("Activation Id Detected", activationId)
      if(!modalIsOpen) {
        setModalIsOpen(true);
        setModalWasOpen(true);
        
        setTimeout(async () => {
          const result = await handleActivation(activationId);
          clearUrlParams();
          if(result && result.message) {
            // activation successful
            setSuccess(true);
          }
          else {
            // activation failed
            //const errorMsg = result.error ? result.error : "An error occurred"
            // toast.error(errorMsg)
            setLoading(false)
          }
        }, 2000)
      }
    }, 500);
  }

  if(!modalIsOpen && !modalWasOpen) {
    return null;
  }

  const closeThisModalAndOpenLoginModal = () => {
    setModalIsOpen(false);
    setShowLoginModal(true);
  }

  const closeThisModalAndOpenSignUpModal = () => {
    setModalIsOpen(false);
    setShowSignUpModal(true);
  }

  
  const modalImg = (loading && !success) ? loadingAnim : success ? checkAnim : crossAnim;
  const modalText = (loading && !success) ? "Activating Account" : success ? "Account Activated Successfully" : "Error Activating Account";
  const subLinkClass = (loading && !success) ? "opacity-0" : "opacity-1";
  const subLinkText = (loading && !success) ? "Please Wait" : success ? "You can now Login" : "Please try Signing Up again";
  
  const handleLinkClick = () => {
    // supposed to be hidden so no click action
    if(subLinkClass.endsWith('0')) {
      return;
    }
    // login
    if(subLinkText.includes("Login")) {
      closeThisModalAndOpenLoginModal();
    }
    // signup
    else {
      closeThisModalAndOpenSignUpModal();
    }
  }
  return (
    <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
      <div className="capped-container">
          <div className="row text-center">
            <div className="col-12 mb-20">
              <img className="modal-logo" src={modalImg} alt="Logo" />
              <div className="modal-heading">{modalText}</div>
            </div>
            <div role="button" tabIndex={0} className={`${subLinkClass} no-account-link flashing-link mt-0`} onClick={handleLinkClick} onKeyDown={handleLinkClick}>{subLinkText}</div>
          </div>
      </div>
    </Modal>
  )
}

export default ActivationModal
