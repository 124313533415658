import * as React from "react"
import HomeHero from "../components/HomeHero"
import Wrapper from "../components/Wrapper"
import Layout from "../components/Layout"
import PostLoop from "../components/PostLoop"
import ActivationModal from "../components/modals/ActivationModal"
import ResetPwModal from "../components/modals/ResetPwModal"
import { graphql } from "gatsby"
import FeaturedPostLoop from "../components/FeaturedPostLoop"
import Seo from "../components/Seo"

// markup
const IndexPage = ({ data }) => {
  console.log(`IndexPage`)
  const featuredPosts = data.allMarkdownRemark.nodes.filter(
    post => post.frontmatter.featured
  )

  return (
    <Wrapper>
      <Layout>
        <Seo homePage={true} />
        <ActivationModal/>
        <ResetPwModal/>
        <HomeHero />
        <div className="main">
          {featuredPosts.length > 0 && (
            <FeaturedPostLoop
              posts={featuredPosts}
              featuredPostCount={data.site.siteMetadata.featuredPostCount}
            />
          )}
          <PostLoop
            posts={data.allMarkdownRemark.nodes}
            postPerPage={data.site.siteMetadata.postPerPage}
            sectionTitle="Latest post"
          />
        </div>
      </Layout>
    </Wrapper>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        featuredPostCount
        postPerPage
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { published: { ne: false } }
        fileAbsolutePath: { regex: "/content/posts/" }
      }
    ) {
      nodes {
        ...PostQueryFragment
      }
    }
  }
`
